<script setup>
import { ref, computed } from 'vue'

// get current device
const slug = window.location.pathname.split('/')[2]

const devices = [
  {
    slug: 'terminal-prime',
    options: [
      '32“ (2K, 12 Touchpoints)',
      '43“ (2K, 12 Touchpoints)',
      '49“ (4K, 12 Touchpoints)',
      '55“ (4K, 12 Touchpoints)'
    ],
    or: [
      'Nein'
    ],
    color: [
      'weiß',
      'anthrazit',
      'Wunschfarbe (RAL)'
    ]
  },
  {
    slug: 'terminal-pro',
    options: [
      '32“ (2K, 12 Touchpoints)',
      '43“ (2K, 12 Touchpoints)',
      '49“ (4K, 12 Touchpoints)'
    ],
    or: [
      'Nein'
    ],
    color: [
      'schwarz',
      'silber'
    ]
  },
  {
    slug: 'terminal-light',
    options: [
      '32“ (2K, 12 Touchpoints)',
      '43“ (2K, 12 Touchpoints)',
      '49“ (4K, 12 Touchpoints)',
      '55“ (4K, 12 Touchpoints)',
      '65“ (4K, 40 Touchpoints)'
    ],
    or: [
      'Nein'
    ],
    color: [
      'schwarz',
      'weiß'
    ]
  },
  {
    slug: 'e-table',
    options: [
      '43“ (2K, 12 Touchpoints)',
      '49“ (4K, 12 Touchpoints)',
      '55“ (4K, 12 Touchpoints)',
      '65“ (4K, 40 Touchpoints)'
    ],
    or: [
      'Nein'
    ],
    color: [
      'weiß',
      'anthrazit',
      'Wunschfarbe (RAL)'
    ]
  },
  {
    slug: 'e-table-light',
    options: [
      '32“ (2K, 12 Touchpoints)',
      '43“ (2K, 12 Touchpoints)',
      '49“ (4K, 12 Touchpoints)',
      '55“ (4K, 12 Touchpoints)',
      '65“ (4K, 40 Touchpoints)'
    ],
    or: [
      'Ja',
      'Nein'
    ],
    color: [
      'schwarz'
    ]
  },
  {
    slug: 'e-table-light-education',
    options: [
      '32“ (2K, 12 Touchpoints)',
      '43“ (2K, 12 Touchpoints)',
      '49“ (4K, 12 Touchpoints)',
      '55“ (4K, 12 Touchpoints)',
      '65“ (4K, 40 Touchpoints)'
    ],
    or: [
      'Ja',
      'Nein'
    ],
    color: [
      'schwarz'
    ]
  },
  {
    slug: 'table-pro',
    options: [
      '32“ (2K, 12 Touchpoints)',
      '43“ (2K, 12 Touchpoints)',
      '49“ (4K, 12 Touchpoints)',
      '55“ (4K, 12 Touchpoints)',
      '65“ (4K, 40 Touchpoints)'
    ],
    or: [
      'Ja',
      'Nein'
    ],
    color: [
      'weiß',
      'anthrazit',
      'Wunschfarbe (RAL)'
    ]
  },
  {
    slug: 'table-light',
    options: [
      '32“ (2K, 12 Touchpoints)',
      '43“ (2K, 12 Touchpoints)',
      '49“ (4K, 12 Touchpoints)',
      '55“ (4K, 12 Touchpoints)'
    ],
    or: [
      'Ja',
      'Nein'
    ],
    color: [
      'weiß',
      'anthrazit',
      'Wunschfarbe (RAL)'
    ]
  },
  {
    slug: 'stele-pro',
    options: [
      '32“ (2K, 12 Touchpoints)',
      '43“ (2K, 12 Touchpoints)',
      '49“ (4K, 12 Touchpoints)',
      '55“ (4K, 12 Touchpoints)'
    ],
    or: [
      'Nein'
    ],
    color: [
      'weiß',
      'anthrazit',
      'Wunschfarbe (RAL)'
    ]
  },
  {
    slug: 'outdoor-stele-pro',
    options: [
      '55“ (4K, Hochformat, 10 TP)'
    ],
    or: [
      'Nein'
    ],
    color: [
      'schwarz'
    ]
  },
  {
    slug: 'smartphone-stele',
    options: [
      '32“ (2K, 12 Touchpoints)',
      '43“ (2K, 12 Touchpoints)',
      '49“ (4K, 12 Touchpoints)',
      '55“ (4K, 12 Touchpoints)'
    ],
    or: [
      'Nein'
    ],
    color: [
      'weiß',
      'anthrazit',
      'Wunschfarbe (RAL)'
    ]
  },
  {
    slug: 'table-prime',
    options: [
      '49“ (4K, 12 Touchpoints)',
      '55“ (4K, 12 Touchpoints)',
      '65“ (4K, 40 Touchpoints)'
    ],
    or: [
      'Ja',
      'Nein'
    ],
    color: [
      'weiß',
      'anthrazit',
      'Wunschfarbe (RAL)'
    ]
  }
]

const device = devices.find(device => device.slug === slug)

const questions = ref([
  {
    question: 'Displaygröße:',
    options: device.options,
    selected: device.options.length === 1 ? 0 : null
  },
  {
    question: 'Objekterkennung:',
    options: device.or,
    selected: device.or.length === 1 ? 0 : null
  },
  {
    question: 'Farbe:',
    options: device.color,
    selected: device.color.length === 1 ? 0 : null
  },
  {
    question: 'Betriebssystem:',
    options: [
      'Windows 11',
      'Android'
    ],
    selected: null
  },
  {
    question: 'In welchen Bereichen soll unser Produkt Sie unterstützen?',
    options: [
      'Marketing',
      'Sales',
      'Produktvorstellung',
      'Leadgenerierung',
      'Sonstiges'
    ],
    selected: null
  },
  {
    question: 'Wussten Sie, dass wir genau für Ihren Bedarf interaktive Anwendungen anbieten?',
    options: [
      'Ja, bitte mehr Informationen',
      'Nein, kein Interesse'
    ],
    selected: null
  },
  {
    question: 'Wann wollen Sie das System kaufen?',
    options: [
      'in den nächsten 2 Wochen',
      'in 2 Monaten',
      'in 1 Jahr',
      'steht noch nicht fest'
    ],
    selected: null
  },
  {
    question: 'Wie sollen wir in Kontakt treten?',
    options: [
      'E-Mail',
      'Rückrufbitte'
    ],
    selected: null
  },
  {
    question: 'Jetzt nur noch einige persönliche Angaben.',
    showForm: true,
    inputs: [
      {
        name: 'Name',
        value: null
      },
      {
        name: 'E-Mail',
        value: null
      },
      {
        name: 'Telefon',
        value: null
      },
      {
        name: 'Unternehmen',
        value: null
      }
    ]
  }
])

const currentQuestion = ref(0)
const finished = ref(false)

const getCurrentQuestion = computed(() => {
  let question = questions.value[currentQuestion.value]
  return question
})

const setAnswer = e => {
  questions.value[currentQuestion.value].selected === e.target.value
  e.target.value = null
}

const previousQuestion = () => {
  if (currentQuestion.value > 0) currentQuestion.value--
}

const nextQuestion = () => {
  if (currentQuestion.value < questions.value.length - 1) currentQuestion.value++
  else {
    finished.value = true

    const payload = `

Kaufpreis ermitteln für ${device.slug}

${questions.value[0].question}
${questions.value[0].options[questions.value[0].selected]}

${questions.value[1].question}
${questions.value[1].options[questions.value[1].selected]}

${questions.value[2].question}
${questions.value[2].options[questions.value[2].selected]}

${questions.value[3].question}
${questions.value[3].options[questions.value[3].selected]}

${questions.value[4].question}
${questions.value[4].options[questions.value[4].selected]}

${questions.value[5].question}
${questions.value[5].options[questions.value[5].selected]}

${questions.value[6].question}
${questions.value[6].options[questions.value[6].selected]}

${questions.value[7].question}
${questions.value[7].options[questions.value[7].selected]}

${questions.value[8].question}
Name:        ${questions.value[8].inputs[0].value}
E-Mail:      ${questions.value[8].inputs[1].value}
Telefon:     ${questions.value[8].inputs[2].value}
Unternehmen: ${questions.value[8].inputs[3].value}

`

    // Send data to backend
    fetch('/request/', {
      method: 'POST',
      body: payload
    })

    setTimeout(() => {
      currentQuestion.value = 0
      finished.value = false
      questions.value.forEach(question => {
        if (question.selected) question.selected = null
        if (question.inputs)   question.inputs.forEach(input => input.value = null)
      })
    }, 5000)
  }
}

</script>


<template>
  <Transition name="bounceRight">
    <section
        class="request"
        :key="`step-${currentQuestion}-${finished}`">
      <div
          class="question"
          v-if="getCurrentQuestion.question && !finished">
        <h5>{{ getCurrentQuestion.question }}</h5>
      </div>
      <div
          class="options"
          v-if="getCurrentQuestion.options && !finished">
        <label
            v-for="(option, index) in getCurrentQuestion.options"
            :class="{ active: index === getCurrentQuestion.selected }">
          <input
              class="form-check"
              type="radio"
              :value="index"
              v-model="getCurrentQuestion.selected"
              @change="setAnswer" />
          {{ option }}
        </label>
      </div>
      <div
          class="form"
          v-if="getCurrentQuestion.showForm && !finished">
        <input
            v-for="input in getCurrentQuestion.inputs"
            class="form-input"
            type="text"
            :placeholder="input.name"
            v-model="input.value" />
      </div>
      <div class="buttons" v-if="!finished">
        <button
            :disabled="currentQuestion === 0"
            @click="previousQuestion"
            class="btn btn-outline">zurück</button>
        <button
            :disabled="questions[currentQuestion].selected === null"
            @click="nextQuestion"
            class="btn btn-outline">
          <span v-if="currentQuestion < questions.length - 1">weiter</span>
          <span v-if="currentQuestion === questions.length - 1">absenden</span>
        </button>
      </div>
      <div
          class="msg msg-hardware-request"
          v-if="finished">
        Vielen Dank für Ihre Anfrage!
      </div>
    </section>
  </Transition>
</template>


<style lang="scss">
.bounceRight-leave-active {
  position: absolute;
  width: calc(100% - 5rem);
}
</style>
