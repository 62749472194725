<script setup>
import { ref, computed } from 'vue'

// get current device
const slug = window.location.pathname.split('/')[2]

const devices = [
  {
    slug: 'terminal-prime',
    options: [
      '1 T = 785 €',
      '2 T = 1413 €',
      '3 T = 1884 €',
      '4 T = 2261 €',
      '5 T = 2552 €',
      '6 T = 3062 €',
    ],
    config: [
      '49“ (4k, 12 Touchpoints)'
    ]
  },
  {
    slug: 'table-pro',
    options: [
      '1 T = 555 €',
      '2 T = 999 €',
      '3 T = 1332 €',
      '4 T = 1599 €',
      '5 T = 1804 €',
      '6 T = 2165 €',
    ],
    config: [
      '49“ (4k, 12 Touchpoints)'
    ]
  },
  {
    slug: 'e-table-light',
    options: [
      '1 T, 49“ 580 €  / 55“ OE 785 €',
      '2 T, 49“ 1044 € / 55“ OE 1413 €',
      '3 T, 49“ 1392 € / 55“ OE 1884 €',
      '4 T, 49“ 1671 € / 55“ OE 2261 €',
      '5 T, 49“ 1885 € / 55“ OE 2552 €',
      '6 T, 49“ 2262 € / 55“ OE 3062 €',
    ],
    config: [
      '49“ 4k',
      '55“ 4k mit Objekterkennung (OE)'
    ]
  },
  {
    slug: 'table-prime',
    options: [
      '1 T = 785 €',
      '2 T = 1413 €',
      '3 T = 1884 €',
      '4 T = 2261 €',
      '5 T = 2552 €',
      '6 T = 3062 €',
    ],
    config: [
      '65“ (4k, 80 Touchpoints)'
    ]
  },
  {
    slug: 'smartphone-stele',
    options: [
      '1 T = 580 €',
      '2 T = 1044 €',
      '3 T = 1392 €',
      '4 T = 1671 €',
      '5 T = 1885 €',
      '6 T = 2262 €',
    ],
    config: [
      '49“ (4k, 12 Touchpoints)'
    ]
  }
]

const device = devices.find(device => device.slug === slug)

const questions = ref([
  {
    question: 'Mietdauer (in Tagen):',
    options: device.options,
    selected: null
  },
  {
    question: 'Konfiguration:',
    options: device.config,
    selected: device.config.length === 1 ? 0 : null
  },
  {
    question: 'Wann wollen Sie das System mieten?',
    options: [
      'in den nächsten 2 Wochen',
      'in 2 Monaten',
      'in 1 Jahr',
      'steht noch nicht fest'
    ],
    selected: null
  },
  {
    question: 'Wussten Sie, dass wir auch passende Software anbieten? Wünschen Sie weitere Informationen?',
    options: [
      'ja',
      'nein'
    ],
    selected: null
  },
  {
    question: 'Wie sollen wir in Kontakt treten?',
    options: [
      'E-Mail',
      'Rückrufbitte'
    ],
    selected: null
  },
  {
    question: 'Jetzt nur noch einige persönliche Angaben.',
    showForm: true,
    inputs: [
      {
        name: 'Name',
        value: null
      },
      {
        name: 'E-Mail',
        value: null
      },
      {
        name: 'Telefon',
        value: null
      },
      {
        name: 'Unternehmen',
        value: null
      }
    ]
  }
])

const currentQuestion = ref(0)
const finished = ref(false)

const getCurrentQuestion = computed(() => {
  let question = questions.value[currentQuestion.value]
  return question
})

const setAnswer = e => {
  questions.value[currentQuestion.value].selected === e.target.value
  e.target.value = null
}

const previousQuestion = () => {
  if (currentQuestion.value > 0) currentQuestion.value--
}

const nextQuestion = () => {
  if (currentQuestion.value < questions.value.length - 1) currentQuestion.value++
  else {
    finished.value = true
    const payload = `

MIETANFRAGE für ${device.slug}

${questions.value[0].question}
${questions.value[0].options[questions.value[0].selected]}

${questions.value[1].question}
${questions.value[1].options[questions.value[1].selected]}

${questions.value[2].question}
${questions.value[2].options[questions.value[2].selected]}

${questions.value[3].question}
${questions.value[3].options[questions.value[3].selected]}

${questions.value[4].question}
${questions.value[4].options[questions.value[4].selected]}

${questions.value[5].question}
Name:        ${questions.value[5].inputs[0].value}
E-Mail:      ${questions.value[5].inputs[1].value}
Telefon:     ${questions.value[5].inputs[2].value}
Unternehmen: ${questions.value[5].inputs[3].value}

`

    // Send data to backend
    fetch('/request/', {
      method: 'POST',
      body: payload
    })

    setTimeout(() => {
      currentQuestion.value = 0
      finished.value = false
      questions.value.forEach(question => {
        if (question.selected) device.config.length === 1 ? question.selected = 0 : question.selected = null
        if (question.inputs)   question.inputs.forEach(input => input.value = null)
      })
    }, 5000)
  }
}
</script>


<template>
  <Transition name="bounceRight">
    <section
        class="request"
        :key="`step-${currentQuestion}-${finished}`">
      <div
          class="question"
          v-if="getCurrentQuestion.question && !finished">
        <h5>{{ getCurrentQuestion.question }}</h5>
      </div>
      <div
          class="options"
          v-if="getCurrentQuestion.options && !finished">
        <label
            v-for="(option, index) in getCurrentQuestion.options"
            :class="{ active: index === getCurrentQuestion.selected }">
          <input
              class="form-check"
              type="radio"
              :value="index"
              v-model="getCurrentQuestion.selected"
              @change="setAnswer" />
          {{ option }}
        </label>
      </div>
      <div
          class="form"
          v-if="getCurrentQuestion.showForm && !finished">
        <input
            v-for="input in getCurrentQuestion.inputs"
            class="form-input"
            type="text"
            :placeholder="input.name"
            v-model="input.value" />
      </div>
      <div class="buttons" v-if="!finished">
        <button
            :disabled="currentQuestion === 0"
            @click="previousQuestion"
            class="btn btn-outline">zurück</button>
        <button
            :disabled="questions[currentQuestion].selected === null"
            @click="nextQuestion"
            class="btn btn-outline">
          <span v-if="currentQuestion < questions.length - 1">weiter</span>
          <span v-if="currentQuestion === questions.length - 1">absenden</span>
        </button>
      </div>
      <div
          class="msg msg-hardware-rent-request"
          v-if="finished">
        Vielen Dank für Ihre Anfrage!
      </div>
    </section>
  </Transition>
  <p class="small mt-2">
    <span class="mark">*</span>
    Sollten Sie an einem längeren Mietzeitraum Interesse haben, treten Sie
    bitte mit uns in Kontakt. Für diesen Zeitraum berechnen wir einen
    individuellen Preis.
  </p>
</template>


<style lang="scss">
.bounceRight-leave-active {
  position: absolute;
  width: 100%;
}
</style>
